import { useContext, useEffect, useRef, useState } from "react";
import Header from "./ui/Header";
import Release from "../classes/Release";
import { getLatestReleases } from "../services/latest-releases";
import { formatTimestamp } from "../services/DateUtils";
import NavBar from "./ui/NavBar";
import CustomSnackbar from "./ui/CustomSnackbar";
import { snackBarConfigType } from "../classes/SnackBarConfig";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

import SecondaryCTAButtons from "./ui/SecondaryCTAButtons";
import FilterMailCircleIcon from "../assets/icons/filter-mail-circle-solid-standard";
import HorseAdCard from "./ui/HorseAdCard";
import { extractAndSetCountriesFromAds, HorseAd } from "../classes/HorseAd";
import HorseDetails from "./HorseDetails";
import Filter from "./ui/Filter";
import { getDocsFromFirestoreWithSortWithoutLimit } from "../services/firebase";
import Add01Icon from "../assets/icons/add-01-solid-standard";
import FilterNew from "./ui/FilterNew";
import RelatedAds from "./ui/RelatedAds";

const Home = () => {
  const [selectedAd, setSelectedAd] = useState<HorseAd | null>(null);
  const [ads, setAds] = useState<HorseAd[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredAds, setFilteredAds] = useState<HorseAd[]>(ads);
  const navigate = useNavigate();

  // Filter states
  const [age, setAge] = useState<number | undefined>(undefined);
  const [category, setCategory] = useState<string[]>([]);
  const [studbook, setStudbook] = useState<string[]>([]);
  const [examination, setExamination] = useState<string[]>([]);
  const [level, setLevel] = useState<string[]>([]);
  const [gender, setGender] = useState<string[]>([]);
  const [color, setColor] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [availableCountries, setAvailableCountries] = useState<string[]>([]);

  const [minAge, setMinAge] = useState<number | undefined>(undefined);
  const [maxAge, setMaxAge] = useState<number | undefined>(undefined);

  const [minHeight, setMinHeight] = useState<number | undefined>(undefined);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  const [minPrice, setMinPrice] = useState<number | undefined>(undefined);
  const [maxPrice, setMaxPrice] = useState<number | undefined>(undefined);

  const [includePricePerRequest, setIncludePricePerRequest] =
    useState<boolean>(true);

  const fetchAndProcessAds = async () => {
    try {
      // Step 1: Fetch all documents, initially sorted by createdtime in descending order
      const res = await getDocsFromFirestoreWithSortWithoutLimit(
        "createdtime",
        "desc"
      );
      const docs = res.docs;

      console.log("Fetched docs:", docs.length);

      // Step 2: Separate documents into those with and without lastPaymentTime
      const docsWithLastPaymentTime = docs.filter(
        (doc) => doc.data().lastPaymentTime
      );
      const docsWithoutLastPaymentTime = docs.filter(
        (doc) => !doc.data().lastPaymentTime
      );

      // Step 3: Sort docs with lastPaymentTime by lastPaymentTime in descending order
      docsWithLastPaymentTime.sort(
        (a, b) => b.data().lastPaymentTime - a.data().lastPaymentTime
      );

      // Step 4: Combine sorted arrays: docs with lastPaymentTime first, then docs without it
      const combinedDocs = [
        ...docsWithLastPaymentTime,
        ...docsWithoutLastPaymentTime,
      ];

      // Step 5: Remove duplicates by creating a unique set of document IDs
      const uniqueDocs = Array.from(
        new Set(combinedDocs.map((doc) => doc.id))
      ).map((id) => combinedDocs.find((doc) => doc.id === id)!); // The '!' ensures that doc is not undefined

      // Step 6: Map documents to HorseAd model and filter out ads that don't meet criteria
      const fetchedAds = uniqueDocs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<HorseAd, "id">),
      })) as HorseAd[];

      // Step 7: Apply additional filters (similar to Swift's `isActiveAd` check)
      const activeAds = fetchedAds.filter((ad: HorseAd) => isActiveAd(ad));

      // Step 8: Update state with filtered ads
      setAds(activeAds);
      setFilteredAds(activeAds);

      // Setting countries for filter
      setAvailableCountries(extractAndSetCountriesFromAds(activeAds));
      console.log("AVAILABLE_COUNTRIES: ", availableCountries);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  const fetchAndProcessAdsOld = async () => {
    try {
      const res = await getDocsFromFirestoreWithSortWithoutLimit(
        "createdtime",
        "desc"
      );
      const docs = res.docs;

      console.log("Fetched docs:", docs.length);

      // Separate the documents with expiryTime from those without
      const docsWithExpiry = docs.filter((doc) => doc.data().expiryTime);
      const docsWithoutExpiry = docs.filter((doc) => !doc.data().expiryTime);

      // Sort the docs with expiryTime in descending order based on expiryTime
      docsWithExpiry.sort((a, b) => b.data().expiryTime - a.data().expiryTime);

      // Sort the docs without expiryTime in descending order based on createdtime
      docsWithoutExpiry.sort(
        (a, b) => b.data().createdtime - a.data().createdtime
      );

      // Combine the sorted arrays
      const sortedDocs = [...docsWithExpiry, ...docsWithoutExpiry];

      // Remove duplicates
      const uniqueDocs = Array.from(
        new Set(sortedDocs.map((doc) => doc.id))
      ).map((id) => sortedDocs.find((doc) => doc.id === id));

      // const fetchedAds = uniqueDocs.map((doc) => ({
      //   id: doc.id,
      //   ...(doc.data() as Omit<HorseAd, "id">),
      // }));

      const fetchedAds = uniqueDocs
        .filter((doc) => doc !== undefined) // Ensure doc is not undefined
        .map((doc) => {
          if (doc) {
            // Additional check for safety
            return {
              id: doc.id,
              ...(doc.data() as Omit<HorseAd, "id">),
            };
          }
          return null; // Return null if doc is undefined, but this should be handled
        })
        .filter((ad) => ad !== null) as HorseAd[]; // Remove null values from the array

      const activeAds = fetchedAds.filter((ad: HorseAd) => isActiveAd(ad));

      setAds(activeAds);
      setFilteredAds(activeAds);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  useEffect(() => {
    fetchAndProcessAds();
  }, []);

  function isPaid(ad: HorseAd): boolean {
    const expiryTime = ad?.expiryTime;
    const currentTime = Date.now(); // Gets the current time in milliseconds

    const expTime = expiryTime ?? 0;

    if (expTime == 0) {
      return true;
    }

    if (currentTime < expTime) {
      return true;
    }

    return false;
  }

  const isActiveAd = (ad: HorseAd): boolean => {
    return ad.status < 2 && isPaid(ad);
  };

  const adOnClick = (ad: HorseAd) => {
    setSelectedAd(ad);
  };

  const closeDetailsView = () => {
    console.log("close button called");
    setSelectedAd(null);
  };

  const openSellHorsePage = () => {
    navigate("/sell");
    // window.open("https://sell.allhorses.nl", "_blank");
  };

  const adRelatedAdOnClick = (ad: HorseAd) => {
    setSelectedAd(ad);
  };

  const topElementRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="mainFrame">
      <Header />
      <div className="flex justify-center items-center p-1 bg-[#ffffff00] relative">
        {/* For transparent edges */}

        <div className="flex flex-col py-2 md:px-7 md:bg-[#386163] rounded-3xl relative ">
          {/* The Main Container */}
          {selectedAd == null && (
            <div className="flex flex-col justify-between items-center pb-8 sm:flex-row sm:space-y-0 sm:space-x-4">
              {/* Main CTA Container */}
              <h1 className="font-semibold text-center sm:text-left sm:mt-4 sm:text-sm text-base md:text-xl lg:text-2xl w-full sm:w-[49vw] mb-4 sm:mb-0 text-white">
                Bereik meer dan 500 potentiële kopers en fascineer meer dan 1000
                kijkers op grote evenementen. Plaats vandaag nog uw advertentie!
              </h1>

              <SecondaryCTAButtons
                label={showFilter ? "Verberg Filter" : "Toon Filter"}
                onClick={() => setShowFilter(!showFilter)}
                ButtonIcon={FilterMailCircleIcon}
                className="h-14 w-auto"
                displayIcon={true}
              ></SecondaryCTAButtons>
            </div>
          )}

          {showFilter && (
            <div className={`filter-container ${showFilter ? "show" : ""}`}>
              {/* Pass them to the Filter component */}
              <FilterNew
                ads={ads}
                setFilteredAds={setFilteredAds}
                hideFilter={() => setShowFilter(false)}
                age={age}
                setAge={setAge}
                category={category}
                setCategory={setCategory}
                studbook={studbook}
                setStudbook={setStudbook}
                examination={examination}
                setExamination={setExamination}
                level={level}
                setLevel={setLevel}
                gender={gender}
                setGender={setGender}
                color={color}
                setColor={setColor}
                minPrice={minPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
                minHeight={minHeight}
                setMinHeight={setMinHeight}
                maxHeight={maxHeight}
                setMaxHeight={setMaxHeight}
                minAge={minAge}
                setMinAge={setMinAge}
                maxAge={maxAge}
                setMaxAge={setMaxAge}
                countries={countries}
                setCountries={setCountries}
                includePricePerRequest={includePricePerRequest}
                setIncludePricePerRequest={setIncludePricePerRequest}
                AVAILABLE_COUNTRIES={availableCountries}
              />
            </div>
            // </div>
          )}

          <div className="flex flex-row justify-center pt-0  rounded-3xl min-h-[38em] overflow-y-auto md:w-full sm:w-full lg:w-[90vw]">
            {/* Horse Card Container */}
            {ads.length === 0 ? (
              <p className="text-center text-gray-500">
                Even geduld, advertenties worden geladen ...
              </p>
            ) : filteredAds.length === 0 ? (
              <p className="text-center text-gray-500">
                Geen advertenties gevonden voor het geselecteerde filter!
              </p>
            ) : selectedAd == null ? (
              <div className="h-full flex flex-wrap justify-evenly  w-[inherit]">
                {filteredAds.map((ad: HorseAd) => (
                  <HorseAdCard
                    key={ad.name + ad.id}
                    ad={ad}
                    onClick={() => adOnClick(ad)}
                    isMyHorsesPage={false}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}

            {selectedAd !== null && (
              <div className="rounded-3xl " ref={topElementRef}>
                <HorseDetails
                  key={selectedAd.id}
                  ad={selectedAd}
                  onClickClose={closeDetailsView}
                  onClickScrollTop={scrollToTop}
                />
                <RelatedAds
                  thisHorse={selectedAd}
                  ads={ads}
                  adRelatedAdOnClick={adRelatedAdOnClick}
                />
              </div>
            )}
          </div>

          {/* <div
            className="w-[32vw] flex justify-around items-center px-6 py-4 bg-[#F0663E] rounded-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#cf6044] hover:shadow-lg absolute bottom-8 left-[34vw] z-10"
            onClick={openSellHorsePage}
          >
            <Add01Icon
              className="text-white mr-2 transition-transform duration-300 ease-in-out hover:rotate-12"
              size={26}
              color={"#FFFFFF"}
            />
            <p className="font-semibold sm:text-lg md:text-lg lg:text-xl text-white transition-colors duration-300 ease-in-out">
              Showcase Your Horse to Thousands!
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
