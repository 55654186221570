"use client";

import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel CSS
// Import your custom icons
import {
  HorseAd,
  getHorseAge,
  getHorseAgeString,
  getHorseHeightString,
  getLocationString,
  getPriceString,
} from "../classes/HorseAd";
import SpecsInfo from "./ui/SpecsInfo";
import SecondaryCTAButtons from "./ui/SecondaryCTAButtons";
import Call02Icon from "../assets/icons/call-02-solid-standard";
import MailSend02Icon from "../assets/icons/mail-send-02-solid-standard";
import WhatsappIcon from "../assets/icons/whatsapp-solid-standard";
import LinkBackwardIcon from "../assets/icons/link-backward-solid-standard";
import parse from "html-react-parser";
import { getUserData } from "../services/firebase";
import { DocumentData } from "firebase/firestore";
import ConfirmDialog from "./ui/ConfirmDialog";
import { CircularProgress } from "@mui/material";
import { getDominantColor } from "../services/custom-utlis";

interface HorseDetailsProps {
  ad: HorseAd;
  onClickClose: () => void;
  onClickScrollTop: () => void;
}

const HorseDetails: React.FC<HorseDetailsProps> = ({
  ad,
  onClickClose,
  onClickScrollTop,
}) => {
  const [seller, setSellerData] = useState<DocumentData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const [openDialogForAdSource, setOpenDialogForAdSource] = useState(false);
  const [action, setAction] = useState<"call" | "email" | "whatsapp" | null>(
    null
  );

  const handleOpenDialog = (
    actionType: "call" | "email" | "whatsapp",
    ad: HorseAd
  ) => {
    setAction(actionType);
    if (ad.id.startsWith("ryz")) {
      if (ad && ad.pageURL && ad.pageURL !== "") {
        setOpenDialogForAdSource(true);
      }
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    if (ad) {
      switch (action) {
        case "call":
          callTheSeller(ad);
          break;
        case "email":
          emailTheSeller(ad);
          break;
        case "whatsapp":
          whatsAppTheSeller(ad);
          break;
      }
    }
    handleCloseDialog();
  };

  const openUrlInAnotherTab = (urlString: string | undefined) => {
    // console.log("opening url: ", urlString);
    window.open(urlString, "_blank");
  };

  const callTheSeller = (ad: HorseAd) => {
    console.log("Calling the seller");
    setIsLoading(true);
    getUserData(ad.selleruserId)
      .then((res) => {
        setIsLoading(false);

        const sellerData = res?.data();
        if (sellerData && sellerData.mobileNumber) {
          window.open(`tel:${sellerData.mobileNumber}`, "_blank");
        } else {
          setOpenDialogForError(true);
          console.log("Seller's mobile number is not available.");
        }
      })
      .catch((error) => {
        setIsLoading(false);

        setOpenDialogForError(true);
        console.error("Error fetching seller data:", error);
      });
  };

  const emailTheSeller = (ad: HorseAd) => {
    console.log("Emailing the seller");
    setIsLoading(true);

    getUserData(ad.selleruserId)
      .then((res) => {
        setIsLoading(false);

        const sellerData = res?.data();
        if (sellerData && sellerData.email) {
          window.location.href = `mailto:${sellerData.email}`;
        } else {
          setOpenDialogForError(true);
          console.log("Seller's email address is not available.");
        }
      })
      .catch((error) => {
        setIsLoading(false);

        setOpenDialogForError(true);
        console.error("Error fetching seller data:", error);
      });
  };

  const whatsAppTheSeller = (ad: HorseAd) => {
    console.log("Sending WhatsApp message to the seller");
    setIsLoading(true);

    getUserData(ad.selleruserId)
      .then((res) => {
        setIsLoading(false);

        const sellerData = res?.data();
        if (sellerData && sellerData.mobileNumber) {
          window.open(`https://wa.me/${sellerData.mobileNumber}`, "_blank");
        } else {
          setOpenDialogForError(true);
          console.log("Seller's WhatsApp number is not available.");
        }
      })
      .catch((error) => {
        setIsLoading(false);

        setOpenDialogForError(true);
        console.error("Error fetching seller data:", error);
      });
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Close the full-screen overlay
  const closeOverlay = () => setSelectedImage(null);

  useEffect(() => {
    onClickScrollTop();
  }, []);

  const imgRef = useRef<HTMLImageElement>(null);
  const [dominantColor, setDominantColor] = useState<string | null>(null); // Start with null

  const handleImageLoad = () => {
    if (imgRef.current) {
      const color = getDominantColor(imgRef.current);
      setDominantColor(color);
    }
  };

  return (
    // <div className="flex flex-row items-start justify-around bg-[#f5f5f5] p-6 rounded-3xl w-[inherit] h-[38em] overflow-y-auto">
    <div className="flex flex-col md:flex-row items-start justify-around  p-6 rounded-3xl w-[inherit] min-h-[38em] overflow-y-auto">
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}

      {/* Horse Details - left */}
      <div className="w-full md:w-[35vw] py-1">
        {/* Horse Image Carousel */}

        <SecondaryCTAButtons
          label={"Terug"}
          onClick={onClickClose}
          ButtonIcon={LinkBackwardIcon}
          className="h-14 w-14"
          displayIcon={true}
        />
        <div
          style={{ boxShadow: "0px 8px 21px #042526" }}
          className="rounded-3xl my-6"
        >
          <Carousel
            showThumbs={false}
            infiniteLoop={false}
            showStatus={false}
            autoPlay={true}
          >
            {ad.media.map((image: string, index: number) => (
              <div
                key={index}
                onClick={() => setSelectedImage(image)}
                className="cursor-zoom-in rounded-3xl overflow-auto"
              >
                <div className="relative ">
                  <div className="absolute inset-0 overflow-hidden rounded-2xl">
                    {/* <img
                    src={image}
                    alt="Horse Background"
                    className="blur-[2em] absolute inset-0 object-cover"
                  /> */}
                  </div>

                  <div
                    className="relative z-10"
                    style={{
                      backgroundColor:
                        dominantColor || "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <img
                      src={image}
                      alt="Horse"
                      width={440}
                      className="w-full h-[26rem] object-contain"
                      ref={imgRef}
                      onLoad={handleImageLoad} // Trigger the color extraction on load
                      crossOrigin="anonymous" // Allows loading cross-origin images anonymously
                    />
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        {/* Full-screen overlay */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center"
            onClick={closeOverlay} // Close overlay when clicking outside the image
          >
            <div className="relative">
              <img
                src={selectedImage}
                alt="Full-screen Horse"
                className="w-auto w-full h-[100vh] object-contain"
              />
              <button
                className="absolute top-2 right-2 text-white bg-[#F0663E] rounded-full p-2"
                onClick={closeOverlay} // Close button inside the overlay
              >
                &times; {/* Close icon */}
              </button>
            </div>
          </div>
        )}

        <h1 className="text-3xl md:text-4xl font-bold text-[#FFF] my-4">
          {ad.name}
        </h1>

        <p className="text-3xl md:text-4xl font-bold text-[#16bb53] mb-4">
          {getPriceString(ad)}
        </p>

        {getLocationString(ad) && (
          <p className="text-xl text-[#FFF] mb-4">
            <span className="font-semibold ">Locatie: </span>
            {getLocationString(ad, true)}
          </p>
        )}
      </div>

      {/* Horse Details - right */}
      <div className="w-full md:w-[40vw] md:p-6 md:py-8">
        {/* Horse Information */}
        <div className=" flex flex-col justify-start mb-4">
          {getHorseAgeString(ad) && (
            <SpecsInfo label="Leeftijd" value={getHorseAgeString(ad)} />
          )}

          {ad.color && <SpecsInfo label="Kleur" value={ad.color} />}
          {ad.gender && <SpecsInfo label="Geslacht" value={ad.gender} />}
          {ad.studbook && <SpecsInfo label="Stamboek" value={ad.studbook} />}
          {getPriceString(ad) && (
            <SpecsInfo label="Prijs (€)" value={`${getPriceString(ad)}`} />
          )}
          {ad.level && <SpecsInfo label="Niveau" value={ad.level} />}
          {getHorseHeightString(ad) && (
            <SpecsInfo label="Hoogte" value={`${getHorseHeightString(ad)}`} />
          )}
          {ad.category && <SpecsInfo label="Categorie" value={ad.category} />}
          {ad.examination && (
            <SpecsInfo label="Keuring" value={ad.examination} />
          )}

          {ad.documents[0] ? (
            <SpecsInfo
              label="Externe Link"
              value={ad.documents[0]}
              isURL={true}
            />
          ) : (
            <></>
          )}
        </div>

        {/* Description */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-[#FFF]">Beschrijving</h3>
          <p className="text-left text-[#FFF]">{parse(ad.description)}</p>
        </div>

        {/* Contact Information */}
        <div className="mt-8">
          <h3 className="text-xl font-semibold text-[#FFF]">
            Contacteer de verkoper:
          </h3>
          <div className="flex flex-wrap mt-4">
            <SecondaryCTAButtons
              label="Call"
              onClick={() => handleOpenDialog("call", ad)}
              ButtonIcon={Call02Icon}
            />
            <SecondaryCTAButtons
              label="Email"
              onClick={() => handleOpenDialog("email", ad)}
              ButtonIcon={MailSend02Icon}
            />
            {ad.contactViaWhatsApp ? (
              <SecondaryCTAButtons
                label="WhatsApp"
                onClick={() => handleOpenDialog("whatsapp", ad)}
                ButtonIcon={WhatsappIcon}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
        title="Bevestig actie"
        message={`Weet u zeker dat u de verkoper ${action} wilt geven?`}
      />
      <ConfirmDialog
        open={openDialogForError}
        onClose={() => setOpenDialogForError(false)}
        onConfirm={() => setOpenDialogForError(false)}
        title="Interne fout"
        message={`De verkoper heeft geen ${action}?`}
      />
      <ConfirmDialog
        open={openDialogForAdSource}
        onClose={() => setOpenDialogForAdSource(false)}
        onConfirm={() => {
          setOpenDialogForAdSource(false);
          openUrlInAnotherTab(ad?.pageURL);
        }}
        title="Originele bron openen"
        message="Wil je de originele bron van de advertentie openen?"
      />
    </div>
  );
};

export default HorseDetails;
