import { FormControlLabel, Checkbox } from "@mui/material";
import { CustomTextInput } from "./CustomTextInput";
import { useContext, useRef, useState } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

interface GetContactedByWhatsAppProps {
  getContactedByWhatsApp: boolean;
  setGetContactedByWhatsApp: (value: boolean) => void;
  className?: string;
}

export const GetContactedByWhatsApp: React.FC<GetContactedByWhatsAppProps> = ({
  getContactedByWhatsApp,
  setGetContactedByWhatsApp,
  className,
}) => {
  const userPhoneNumberRef: React.RefObject<HTMLInputElement> = useRef(null);

  const { currentUser, getCurrentUser } = useContext(GlobalContext);

  const [error, setInputError] = useState("");
  const [message, setMessage] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const handleContactRequest = (perssmission: boolean) => {
    const userDetils = getCurrentUser();
    if (userDetils?.mobileNumber && userDetils?.mobileNumber.length > 7) {
      setIsValidPhoneNumber(true);
      setGetContactedByWhatsApp(perssmission);
      setMessage(
        "Geïnteresseerde kunnen contact opnemen via whatsapp op het nummer: "
      );
    } else {
      setInputError(
        "Er is geen mobielnummer gekoppeld aan uw profiel. Voeg een mobielnummer toe en probeer het opnieuw"
      );
    }
  };

  return (
    <div className={`form-row ${className}`}>
      <p className="form-element flex flex-col ml-4 mb-0">
        <label className="font-semibold text-xl mb-2">Contact</label>

        <div className="flex items-center justify-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={getContactedByWhatsApp}
                onChange={(e) => {
                  handleContactRequest(e.target.checked);
                }}
                name="acceptTerms"
                sx={{
                  color: "#F0663E", // Unchecked color
                  "&.Mui-checked": {
                    color: "#F0663E", // Checked color
                  },
                }}
              />
            }
            label={""}
            style={{ marginRight: 0 }}
          />
          <label className="font-medium text-xl ">
            Neem contact op via WhatsApp
          </label>
        </div>

        {message && getContactedByWhatsApp && (
          <p className="">
            {message}
            <span className="font-semibold">{currentUser.mobileNumber} </span>
          </p>
        )}
        {error && <p className="error-text">{error}</p>}
      </p>
    </div>
  );
};
