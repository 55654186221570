import React from "react";
import {
  getHorseAge,
  HORSE_CATEGORIES,
  HORSE_COLORS,
  HORSE_EXAMINATIONS,
  HORSE_GENDERS,
  HORSE_LEVELS,
  HORSE_STUDBOOKS,
  HorseAd,
} from "../../classes/HorseAd";

import { FormControlLabel, Checkbox } from "@mui/material";

interface FilterProps {
  ads: HorseAd[];
  setFilteredAds: (ads: HorseAd[]) => void;
  hideFilter: () => void;
  // Add the filter props
  age: number | undefined;
  setAge: (age: number | undefined) => void;
  category: string[];
  setCategory: (category: string[]) => void;
  studbook: string[];
  setStudbook: (studbook: string[]) => void;
  examination: string[];
  setExamination: (examination: string[]) => void;
  level: string[];
  setLevel: (level: string[]) => void;
  gender: string[];
  setGender: (gender: string[]) => void;
  color: string[];
  setColor: (color: string[]) => void;
  countries: string[];
  setCountries: (countries: string[]) => void;
  minPrice: number | undefined;
  setMinPrice: (price: number | undefined) => void;
  maxPrice: number | undefined;
  setMaxPrice: (price: number | undefined) => void;
  minHeight: number | undefined;
  setMinHeight: (price: number | undefined) => void;
  maxHeight: number | undefined;
  setMaxHeight: (price: number | undefined) => void;
  minAge: number | undefined;
  setMinAge: (price: number | undefined) => void;
  maxAge: number | undefined;
  setMaxAge: (price: number | undefined) => void;
  includePricePerRequest: boolean;
  setIncludePricePerRequest: (include: boolean) => void;
  AVAILABLE_COUNTRIES: string[];
}

const FilterNew: React.FC<FilterProps> = ({
  ads,
  setFilteredAds,
  hideFilter,
  age,
  setAge,
  category,
  setCategory,
  studbook,
  setStudbook,
  examination,
  setExamination,
  level,
  setLevel,
  gender,
  setGender,
  color,
  setColor,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  minHeight,
  setMinHeight,
  maxHeight,
  setMaxHeight,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  countries,
  setCountries,
  includePricePerRequest,
  setIncludePricePerRequest,
  AVAILABLE_COUNTRIES,
}) => {
  const applyFilter = () => {
    console.log("apply filter called");
    const filtered = ads.filter((ad) => {
      // Get the horse's age in years
      const calculatedAge = parseInt(getHorseAge(ad));

      // Check if the horse's age matches the user input (if provided)
      const matchesAge = age !== undefined ? calculatedAge === age : true;

      // Check if the horse's category is included in the selected categories
      const matchesCategory =
        category.length > 0 ? category.includes(ad.category) : true;

      // Check if the horse's studbook is included in the selected studbooks
      const matchesStudbook =
        studbook.length > 0 ? studbook.includes(ad.studbook) : true;

      // Check if the horse's examination is included in the selected examinations
      const matchesExamination =
        examination.length > 0 ? examination.includes(ad.examination) : true;

      // Check if the horse's level is included in the selected levels
      const matchesLevel = level.length > 0 ? level.includes(ad.level) : true;

      // Check if the horse's gender is included in the selected genders
      const matchesGender =
        gender.length > 0 ? gender.includes(ad.gender) : true;

      // Check if the horse's color is included in the selected colors
      const matchesColor = color.length > 0 ? color.includes(ad.color) : true;

      // check if the ad country matches with the selected country
      const matchesCountry =
        countries.length > 0 ? checkIfCountryMatches(countries, ad) : true;

      // Check if the horse's height falls within the specified range (if provided)
      const matchesMinMaxHeight =
        (minHeight !== undefined ? ad.height >= minHeight : true) &&
        (maxHeight !== undefined ? ad.height <= maxHeight : true);

      // Check if the horse's age falls within the specified range (if provided)
      const matchesMinMaxAge =
        (minAge !== undefined ? calculatedAge >= minAge : true) &&
        (maxAge !== undefined ? calculatedAge <= maxAge : true);

      // Check if the horse's price falls within the specified range (if provided)
      const matchesMinMaxPrice =
        (minPrice !== undefined ? ad.price.actualAmount >= minPrice : true) &&
        (maxPrice !== undefined ? ad.price.actualAmount <= maxPrice : true);

      console.log("Min Age: ", minAge);

      // Check if the horse's price matches the includePricePerRequest criteria
      const matchesPricePerRequest = ad.price.actualAmount === 0;

      const matchesPrice = includePricePerRequest
        ? matchesMinMaxPrice || matchesPricePerRequest // Include both ads with price == 0 and those that match min/max price
        : matchesMinMaxPrice && !matchesPricePerRequest; // Exclude ads with price == 0 and include those within the price range

      return (
        matchesAge &&
        matchesCategory &&
        matchesStudbook &&
        matchesExamination &&
        matchesLevel &&
        matchesGender &&
        matchesColor &&
        matchesMinMaxPrice &&
        matchesPrice &&
        matchesMinMaxHeight &&
        matchesMinMaxAge &&
        matchesCountry
      );
    });

    // Set the filtered ads based on the applied filters
    setFilteredAds(filtered);
    hideFilter();
  };

  const checkIfCountryMatches = (countries: string[], ad: HorseAd): boolean => {
    if (!countries || countries.length === 0) return false; // Handle empty or null countries list

    const locationString = ad.locationString?.toLowerCase() || "";
    const locationCountry = ad.location?.country?.toLowerCase() || "";

    // Check if any country matches in either locationString or location.country
    return countries.some((country) => {
      const lowerCaseCountry = country.toLowerCase();
      return (
        locationString.includes(lowerCaseCountry) ||
        locationCountry.includes(lowerCaseCountry)
      );
    });
  };

  const clearFilterParams = () => {
    setAge(undefined);

    setCategory([]);
    setStudbook([]);
    setExamination([]);
    setLevel([]);
    setGender([]);
    setColor([]);
    setCountries([]);
    setIncludePricePerRequest(true);

    setMinPrice(undefined);
    setMaxPrice(undefined);

    setMinHeight(undefined);
    setMaxHeight(undefined);

    setMinAge(undefined);
    setMaxAge(undefined);

    setFilteredAds(ads);
    hideFilter();
  };

  // Function to handle selection from dropdown
  const handleSelectChangeCategory = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCategory = event.target.value;

    // Check if the selected category is already in the array
    if (selectedCategory && !category.includes(selectedCategory)) {
      setCategory([...category, selectedCategory]); // Add the selected category
    }

    // Optionally clear the selection after adding
    event.target.value = ""; // Clear the dropdown selection
  };

  // // Function to handle multi-select from dropdown
  // const handleMultiSelectCategory = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   const selectedOptions = Array.from(
  //     event.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   setCategory(selectedOptions);
  // };

  // // Function to handle multi-select logic
  // const handleMultiSelectCategory = (value: string) => {
  //   if (category.includes(value)) {
  //     // Remove the value if it's already selected
  //     setCategory(category.filter((cat) => cat !== value));
  //   } else {
  //     // Add the value if it's not selected
  //     setCategory([...category, value]);
  //   }
  // };

  return (
    <div className="rounded-2xl">
      <h2 className="text-xl font-semibold mb-2 text-[#FFF]">
        Advertenties filteren
      </h2>

      <div className="flex content-start flex-wrap justify-start overflow-auto">
        {/* Category Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Categorie
          </label>
          <select
            onChange={(e) => {
              const selectedCategory = e.target.value;
              if (selectedCategory && !category.includes(selectedCategory)) {
                setCategory([...category, selectedCategory]); // Add selected category
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem] rounded-xl text-[#10393B] filter-fields"
            defaultValue="" // Set the default value to empty
          >
            <option value="">Selecteer een categorie</option>
            {HORSE_CATEGORIES.map((cat: string, index) => (
              <option key={cat + index} value={cat}>
                {cat}
              </option>
            ))}
          </select>
          {/* Display selected categories */}
          <div className="mt-2 flex flex-col">
            {category.length > 0 ? (
              category.map((cat) => (
                <span
                  key={cat}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black "
                >
                  {cat}{" "}
                  <button
                    onClick={() =>
                      setCategory(category.filter((item) => item !== cat))
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Studbook Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Stamboek
          </label>
          <select
            onChange={(e) => {
              const selectedStudbook = e.target.value;
              if (selectedStudbook && !studbook.includes(selectedStudbook)) {
                setStudbook([...studbook, selectedStudbook]); // Add selected studbook
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            defaultValue=""
          >
            <option value="">Kies een stamboek</option>
            {HORSE_STUDBOOKS.map((book: string, index) => (
              <option key={book + index} value={book}>
                {book}
              </option>
            ))}
          </select>
          {/* Display selected studbooks */}
          <div className="mt-2 flex flex-col">
            {studbook.length > 0 ? (
              studbook.map((book) => (
                <span
                  key={book}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black "
                >
                  {book}{" "}
                  <button
                    onClick={() =>
                      setStudbook(studbook.filter((item) => item !== book))
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Countries Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Locatie
          </label>
          <select
            onChange={(e) => {
              const selectedCountry = e.target.value;
              if (selectedCountry && !countries.includes(selectedCountry)) {
                setCountries([...countries, selectedCountry]);
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            defaultValue=""
          >
            <option value="">Kies een land</option>
            {AVAILABLE_COUNTRIES.map((country: string, index) => (
              <option key={country + index} value={country}>
                {country}
              </option>
            ))}
          </select>
          {/* Display selected countries */}
          <div className="mt-2 flex flex-col">
            {countries.length > 0 ? (
              countries.map((country) => (
                <span
                  key={country}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black"
                >
                  {country}{" "}
                  <button
                    onClick={() =>
                      setCountries(countries.filter((item) => item !== country))
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Examination Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Keuring
          </label>
          <select
            onChange={(e) => {
              const selectedExamination = e.target.value;
              if (
                selectedExamination &&
                !examination.includes(selectedExamination)
              ) {
                setExamination([...examination, selectedExamination]); // Add selected examination
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            defaultValue=""
          >
            <option value="">Selecteer een keuring</option>
            {HORSE_EXAMINATIONS.map((exam: string, index) => (
              <option key={exam + index} value={exam}>
                {exam}
              </option>
            ))}
          </select>
          {/* Display selected examinations */}
          <div className="mt-2 flex flex-col">
            {examination.length > 0 ? (
              examination.map((exam) => (
                <span
                  key={exam}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black "
                >
                  {exam}{" "}
                  <button
                    onClick={() =>
                      setExamination(
                        examination.filter((item) => item !== exam)
                      )
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Level Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Niveau
          </label>
          <select
            onChange={(e) => {
              const selectedLevel = e.target.value;
              if (selectedLevel && !level.includes(selectedLevel)) {
                setLevel([...level, selectedLevel]); // Add selected level
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            defaultValue=""
          >
            <option value="">Kies een niveau</option>
            {HORSE_LEVELS.map((lvl: string, index) => (
              <option key={lvl + index} value={lvl}>
                {lvl}
              </option>
            ))}
          </select>
          {/* Display selected levels */}
          <div className="mt-2 flex flex-col">
            {level.length > 0 ? (
              level.map((lvl) => (
                <span
                  key={lvl}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black "
                >
                  {lvl}{" "}
                  <button
                    onClick={() =>
                      setLevel(level.filter((item) => item !== lvl))
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Gender Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Geslacht
          </label>
          <select
            onChange={(e) => {
              const selectedGender = e.target.value;
              if (selectedGender && !gender.includes(selectedGender)) {
                setGender([...gender, selectedGender]); // Add selected gender
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            defaultValue=""
          >
            <option value="">Selecteer geslacht</option>
            {HORSE_GENDERS.map((gen: string, index) => (
              <option key={gen + index} value={gen}>
                {gen}
              </option>
            ))}
          </select>
          {/* Display selected genders */}
          <div className="mt-2 flex flex-col">
            {gender.length > 0 ? (
              gender.map((gen) => (
                <span
                  key={gen}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black "
                >
                  {gen}{" "}
                  <button
                    onClick={() =>
                      setGender(gender.filter((item) => item !== gen))
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Color Dropdown */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Kleuren
          </label>
          <select
            onChange={(e) => {
              const selectedColor = e.target.value;
              if (selectedColor && !color.includes(selectedColor)) {
                setColor([...color, selectedColor]); // Add selected color
              }
              e.target.value = ""; // Clear selection
            }}
            className="block w-full p-2 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            defaultValue=""
          >
            <option value="">Kies een kleur</option>
            {HORSE_COLORS.map((clr: string, index) => (
              <option key={clr + index} value={clr}>
                {clr}
              </option>
            ))}
          </select>
          {/* Display selected colors */}
          <div className="mt-2 flex flex-col">
            {color.length > 0 ? (
              color.map((clr) => (
                <span
                  key={clr}
                  className="inline-block bg-[#d6dedf] rounded px-2 py-1 text-xs mb-2 text-black "
                >
                  {clr}{" "}
                  <button
                    onClick={() =>
                      setColor(color.filter((item) => item !== clr))
                    }
                    className="text-[#F0663E] py-1 px-2 bg-red-200 rounded-full font-bold"
                  >
                    x
                  </button>
                </span>
              ))
            ) : (
              <span className="text-xs text-gray-400"></span>
            )}
          </div>
        </div>

        {/* Age Input */}
        {/* <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Leeftijd
          </label>
          <input
            type="number"
            value={age || ""}
            onChange={(e) => setAge(Number(e.target.value))}
            className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            placeholder="Enter age"
          />
        </div> */}

        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Min. Hoogte (60 - 215)
          </label>
          <input
            type="number"
            value={minHeight || ""}
            onChange={(e) => setMinHeight(Number(e.target.value))}
            className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            placeholder="Min hoogte in cms"
          />
        </div>

        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Max. Hoogte (60 - 215)
          </label>
          <input
            type="number"
            value={maxHeight || ""}
            onChange={(e) => setMaxHeight(Number(e.target.value))}
            className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            placeholder="Max hoogte in cms"
          />
        </div>

        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Min. Leeftijd (0 - 45)
          </label>
          <input
            type="number"
            value={minAge || "0"}
            onChange={(e) => setMinAge(Number(e.target.value))}
            className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            placeholder="Min leeftijd"
          />
        </div>

        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Max. Leeftijd (0 - 45)
          </label>
          <input
            type="number"
            value={maxAge || ""}
            onChange={(e) => setMaxAge(Number(e.target.value))}
            className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
            placeholder="Max leeftijd"
          />
        </div>

        {/* Price Checkbox */}
        <div className="mb-2 mr-2">
          <label className="block text-sm font-medium text-[#FFF] mb-1">
            Prijs
          </label>
          <div className="flex items-center justify-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={includePricePerRequest}
                  onChange={(e) => {
                    setIncludePricePerRequest(e.target.checked);
                  }}
                  name=""
                  sx={{
                    color: "#F0663E", // Unchecked color
                    "&.Mui-checked": {
                      color: "#F0663E", // Checked color
                    },
                  }}
                />
              }
              label={""}
              style={{ marginRight: 0 }}
            />
            <label className="font-medium text-base text-[#FFF]">
              Inclusief Prijs op aanvraag
            </label>
          </div>
        </div>

        {includePricePerRequest ? null : (
          <>
            {/* Min Price Input */}
            <div className="mb-2 mr-2">
              <label className="block text-sm font-medium text-[#FFF] mb-1">
                Min. Prijs
              </label>
              <input
                type="number"
                value={minPrice || ""}
                onChange={(e) => setMinPrice(Number(e.target.value))}
                className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
                placeholder="Min price"
              />
            </div>

            {/* Max Price Input */}
            <div className="mb-2 mr-2">
              <label className="block text-sm font-medium text-[#FFF] mb-1">
                Max. Prijs
              </label>
              <input
                type="number"
                value={maxPrice || ""}
                onChange={(e) => setMaxPrice(Number(e.target.value))}
                className="block w-full p-2 pl-3 h-[2.5rem]  rounded-xl text-[#10393B] filter-fields"
                placeholder="Max price"
              />
            </div>
          </>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end mb-4 my-2">
        <button
          onClick={clearFilterParams}
          className="py-2 px-4  text-[#F0663E] rounded-xl mr-3"
        >
          Verwijderen
        </button>
        <button
          onClick={applyFilter}
          className="py-2 px-4 bg-[#F0663E] hover:bg-[#cf6044] text-white rounded-xl"
        >
          Filter Toepassen
        </button>
      </div>
    </div>
  );
};

export default FilterNew;
