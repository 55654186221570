import React, { useState, useRef } from "react";
import { CustomTextInput } from "./CustomTextInput";
import { AdLocation } from "../../classes/HorseAd";
import { FormControlLabel, Checkbox } from "@mui/material";

interface LocationInputProps {
  location: AdLocation | null;
  setLocation: (location: AdLocation | null) => void;
}

const LocationInput: React.FC<LocationInputProps> = ({
  location,
  setLocation,
}) => {
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [locationError, setLocationError] = useState<string | null>(null); // For showing errors
  const locationRef = useRef<HTMLInputElement>(null);
  const locationManualRef = useRef<HTMLInputElement>(null);

  const handleCurrentLocation = () => {
    if (!useCurrentLocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        console.log("location getCurrentPosition: ", position);

        const cooler = process.env.REACT_APP_MAP_COOLER;
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${cooler}`;

        const response = await fetch(geocodeUrl);
        const data = await response.json();

        console.log("location response: ", response);
        console.log("location data: ", data);

        if (data.results && data.results.length > 0) {
          const result = data.results[0].address_components;
          const formattedLocation: AdLocation = {
            addressLine1: result[0].long_name,
            addressLine2: result[1]?.long_name || "",
            area_code:
              result.find((component: any) =>
                component.types.includes("postal_code")
              )?.long_name || "",
            city:
              result.find((component: any) =>
                component.types.includes("locality")
              )?.long_name || "",
            country:
              result.find((component: any) =>
                component.types.includes("country")
              )?.long_name || "",
            state:
              result.find((component: any) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "",
            lat: Math.round(lat * 1000),
            longitude: Math.round(lng * 1000),
          };

          setLocation(formattedLocation);
          setIsEditable(false); // Disable input box
          setLocationError(null); // Clear any previous error
        } else {
          setLocationError(
            "Unable to resolve your current location. Please try again."
          );
        }
      });
    } else {
      setIsEditable(true); // Enable input box
      setLocation(null); // Clear the location data
      setLocationError(null); // Clear any previous error
    }
  };

  const handleManualLocation = async () => {
    if (locationManualRef.current && locationManualRef.current.value) {
      console.log(
        "location handleManualLocation: ",
        locationManualRef.current.value
      );

      const address = locationManualRef.current.value;
      const cooler = process.env.REACT_APP_MAP_COOLER;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${cooler}`;

      const response = await fetch(geocodeUrl);
      const data = await response.json();

      console.log("location response: ", response);
      console.log("location data: ", data);

      if (data.results && data.results.length > 0) {
        const result = data.results[0].address_components;
        const formattedLocation: AdLocation = {
          addressLine1: result[0].long_name,
          addressLine2: result[1]?.long_name || "",
          area_code:
            result.find((component: any) =>
              component.types.includes("postal_code")
            )?.long_name || "",
          city:
            result.find((component: any) =>
              component.types.includes("locality")
            )?.long_name || "",
          country:
            result.find((component: any) => component.types.includes("country"))
              ?.long_name || "",
          state:
            result.find((component: any) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "",
          lat: Math.round(data.results[0].geometry.location.lat * 1000),
          longitude: Math.round(data.results[0].geometry.location.lng * 1000),
        };

        setLocation(formattedLocation);
        setLocationError(null); // Clear error on successful location
      } else {
        setLocationError("Please enter a valid address"); // Show error if the location is not resolved
      }
    }
  };

  return (
    <div className="form-row">
      <p className="form-element flex flex-col ml-4 mb-0">
        <label className="font-semibold text-xl mb-2">Locatie</label>

        <div className="flex items-center justify-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={useCurrentLocation}
                onChange={(e) => {
                  setUseCurrentLocation(e.target.checked);
                  handleCurrentLocation();
                }}
                name="acceptTerms"
                sx={{
                  color: "#F0663E", // Unchecked color
                  "&.Mui-checked": {
                    color: "#F0663E", // Checked color
                  },
                }}
              />
            }
            label={""}
            style={{ marginRight: 0 }}
          />
          <label className="font-medium text-xl ">
            Gebruik huidige locatie
          </label>
        </div>
        {useCurrentLocation ? (
          <CustomTextInput
            isValid={!locationError}
            label=""
            placeholder={
              `${location?.addressLine1}, ${location?.addressLine2}, ${location?.city}, ${location?.state}` ||
              ""
            }
            id="location"
            name="location"
            maxLength={100}
            inputRef={locationRef}
            type="text"
            handleChange={() => console.log("est")}
            disabled={true}
            className="!my-1"
          />
        ) : (
          <CustomTextInput
            isValid={!locationError}
            label=""
            placeholder="voer de locatie van het paard in"
            id="location"
            name="location"
            maxLength={100}
            inputRef={locationManualRef}
            type="text"
            handleChange={() => {}}
            onFocusChange={handleManualLocation}
            className="!my-1"
            disabled={false}
          />
        )}
        {/* Show error message if the location cannot be resolved */}
        {locationError && <p className="error-text">{locationError}</p>}
      </p>
    </div>
  );
};

export default LocationInput;
